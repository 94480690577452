var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative text-sm"},[(_vm.isDropdownVisible)?_c('div',{staticClass:"backdrop-overlay",attrs:{"data-testid":"dropdown-multiselect__backdrop"},on:{"click":_vm.onClickOutside}}):_vm._e(),_c('div',{class:[
      'popup-button bg-white',
      _vm.isDropdownVisible
        ? 'rounded-t-md border border-b-0'
        : 'rounded-md border',
      _vm.isError ? 'border-lpRed4' : 'border-grayPensive',
      { disabled: _vm.disabled } ],attrs:{"tabindex":"0","data-testid":"dropdown-multiselect__input"},on:{"click":_vm.onClickField,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickField($event)}}},[_c('div',{staticClass:"flex flex-wrap items-center gap-3"},[(_vm.tempValue.length === 0)?_c('span',{staticClass:"text-gray-1"},[_vm._v(_vm._s(_vm.placeholder))]):(_vm.isAllOriginalOptionSelected)?_c('div',{class:['tags', { disabled: _vm.disabled }],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSelectAll($event)}}},[_c('span',[_vm._v("Semua")]),_c('CloseLine',{attrs:{"fill":"#78787A","width":"16","height":"16"}})],1):_vm._l((_vm.tempValue),function(name){return _c('div',{key:name,class:['tags', { disabled: _vm.disabled }],attrs:{"data-testid":("dropdown-multiselect__tag-" + name)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (function () {
            _vm.onRemoveItem(name)
          })($event)}}},[_c('span',[_vm._v(_vm._s(name))]),_c('CloseLine',{attrs:{"fill":"#78787A","width":"16","height":"16"}})],1)}),_c('div',{class:[
          'chevron',
          {
            'chevron-up': _vm.isDropdownVisible,
          } ]},[_c('CaretDownIcon',{attrs:{"fill":"#A7A8AA"}})],1)],2)]),(_vm.isDropdownVisible)?_c('div',{class:[
      'absolute flex flex-col bg-white w-full z-50 p-1 border-grayPensive rounded-b-md shadow-xl',
      _vm.isDropdownVisible ? 'border border-t-0' : 'border' ]},[(_vm.withSearch)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],staticClass:"outline-none w-full px-4 py-2",attrs:{"type":"text","placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value}}}):_vm._e(),_c('div',{staticClass:"max-h-72 overflow-y-auto"},[(_vm.filteredOptions.length)?_c('ul',[_c('li',{staticClass:"flex px-4 gap-4 py-2 items-center cursor-pointer",on:{"click":_vm.onSelectAll}},[_c('CheckBox',{attrs:{"isChecked":_vm.isSelectedAll}}),_c('span',{staticClass:"text-bgMatterhorn"},[_vm._v("Pilih Semua")])],1),_vm._l((_vm.filteredOptions),function(option){return _c('li',{key:option.value,staticClass:"flex px-4 gap-4 py-2 items-center cursor-pointer",attrs:{"data-testid":("dropdown-multiselect__option-" + (option.value))},on:{"click":function () { return _vm.onSelectOption(option.label); }}},[_c('CheckBox',{attrs:{"data-testid":("dropdown-multiselect__checkbox-" + (option.value)),"isChecked":_vm.isChecked(option.label),"disabled":_vm.isAllOriginalOptionSelected}}),_c('span',{staticClass:"text-bgMatterhorn"},[_vm._v(_vm._s(option.label))])],1)})],2):_c('div',{staticClass:"list-empty"},[_vm._v(" Apa yang Anda cari tidak ditemukan atau tidak ada. ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }